.container-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
}

input {
  height: 50px;
  background-color: #fbfffd;
  border: none;
  color: black;
  padding: 10px;
}
.input-true {
  border-bottom: 1px solid #40f1a8;
}

.input-false {
  border-bottom: 1px solid #e71010;
}

hr {
  display: block;
  height: 1.5px;
  border: 0;
  border-top: 1px solid #dadada;
  margin: 1em 0;
  padding: 0;
}

button {
  background-color: #0b3838;
  color: white;
  border: none;
  border-radius: 3em;
  height: 50px;
  width: 50%;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}

.main-title {
  margin-left: 1em;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: relative;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

p {
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding-right: 1em;
}

.icon-container {
  margin-right: 0.5em;
  margin-top: 0.2em;
}

.error {
  color: red;
}

#showPasswordIcon {
  position: absolute;
  top: 5.6%;
  right: 11%;
  cursor: pointer;
}

#showPasswordConfirmationIcon {
  position: absolute;
  top: 27%;
  right: 11%;
  cursor: pointer;
}

.confirmation-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.green-title {
  color: #168557;
}

.green-text {
  color: #0b3838;
  text-align: center;
}
