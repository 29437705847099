.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
}

.green-title {
  color: #168557;
}

.green-text {
  color: #0b3838;
  text-align: center;
}
